<template>
  <v-overlay :value="show">
    <v-card
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <div class="d-flex justify-space-between py-3 px-5">
        <h3 class="py-2">
          Прикрепить разрешение
        </h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-col
          class="white"
          cols="12"
        >
          <v-row>
            <v-col cols="4">
              <label>
                Номер выпуска пассажирской таможенной декларации
                <v-text-field
                  v-model="releaseNumber"
                  dense
                  hide-details
                  outlined
                />
              </label>
            </v-col>
            <v-col cols="2">
              <label for="">
                Дата выпуска
                <custom-date-picker v-model="releaseDate" />
              </label>
            </v-col>
          </v-row>
        </v-col>
        <div class="d-flex justify-end">
          <v-btn
            :disabled="!orderId"
            dark
            color="#5CB7B1"
            class="mx-5 mt-5"
            @click="submit"
          >
            Отправить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from 'vuex';
import axios from "axios";
import {MSG} from "@/http-routes";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";

export default {
  components: {CustomDatePicker},
  mixins: [modalWindowMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    rootUserId: {
      type: Number,
      required: true,
    },
    module: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    releaseNumber: null,
    releaseDate:null,
    orderId:null
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      items: "journal/getItems"
    })
  },
  watch: {
    show: {
      handler(nv) {
        if (nv === false){
          this.clear()
        }else {
          this.getOrderId()
        }
      },
      immediate: true
    }
  },
  methods: {
    getOrderId(){
      this.$store.dispatch('rs/getOrderId', this.id).then(res => {
        this.orderId = res.data
      })
    },
    clear() {
      this.releaseNumber = null
      this.releaseDate = null
    },
    submit() {
      if(this.releaseNumber && this.releaseDate && this.orderId) {
        axios.post( `${MSG}newdeclarant/application/finish`, {
          releaseNumber:this.releaseNumber,
          releaseDate:this.releaseDate,
          id:this.orderId,
        }).then(() => {
          this.$success("Отправлено")
          this.clear()
          this.close()
        }).catch(() => this.$error())
      }else {
        this.$error("Имеются незаполненные поля")
      }
    }
  }


}
</script>
